import { Divider, Table, Alert } from "antd";
import authenticationService from "../../../services/authentication.service";
import studentClassService from "../../../services/student-class.service";
import { useLoaderData } from "react-router-dom";
import { useEffect, useState } from "react";
import { EyeFilled } from "@ant-design/icons";
import subjectAllocationService from "../../../services/subject-allocation.service";

export async function fetchSubjects() {
  try {
    const studentUserId = authenticationService.getUserId();
    const response =
      await studentClassService.getStudentSubjectsByStudentUserId(
        studentUserId
      );
    const subjects = response.data;
    return { subjects };
  } catch (error) {
    return [];
  }
}

const StudentSubjectsList = () => {
  const { subjects } = useLoaderData();
  const [updatedSubjects, setUpdatedSubjects] = useState([]);
  const [subjectAllocationList, setSubjectAllocationList] = useState([]);
  const [studentClassroom, setStudentClassroom] = useState({});

  const tenantId = authenticationService.getUserTenantId();

  const fetchSubjectAllocations = async () => {
    try {
      const response =
        await subjectAllocationService.getSubjectAllocationByInstitutionId(
          tenantId
        );

      if (response.status === 200) {
        setSubjectAllocationList(response.data);
        console.log("subject allocation", response.data);
      } else {
        console.log("Request was not successful. Status:", response.status);
      }
    } catch (error) {
      console.error("Error occurred during fetching subjects:", error);
    }
  };

  async function fetchStudentDashboardLoader() {
    try {
      const studentUserId = authenticationService.getUserId();
      const studentClassroomResponse =
        await studentClassService.getStudentClassByStudentUserId(studentUserId);
      if (studentClassroomResponse?.status === 200) {
        // console.log("classroom", `${studentClassroomResponse.data.classroom.level.name}${studentClassroomResponse.data.classroom.name}`)
        setStudentClassroom(
          `${studentClassroomResponse.data.classroom.level.name}${studentClassroomResponse.data.classroom.name}`
        );
      }
    } catch (e) {
      return [];
    }
  }

  useEffect(() => {
    const fetch_all = async () => {
      await Promise.all([
        fetchStudentDashboardLoader(),
        fetchSubjectAllocations(),
      ]);
    };
    fetch_all();
  }, []);

  useEffect(() => {
    console.log("subject allllocation", subjectAllocationList);
    console.log("subjects ssf", subjects);
  }, [subjectAllocationList, subjects]);

  useEffect(() => {
    console.log("subjects ssf", subjects);
    console.log("subject allllocation", subjectAllocationList);
   

    const updated_subjects = [];

    subjects.forEach((subject) => {
      // console.log(subject)
      const teacher_data = subjectAllocationList.filter(
        ({id}) => id === subject.id
      )[0]?.teacher?.user
      
      console.log("teacher data", teacher_data);
      const teacher_name = teacher_data ? `${teacher_data?.firstName} ${teacher_data?.lastName}` : 'No teacher allocated yet'
      const updated_subject = {...subject, 'teacher_name': teacher_name}
      updated_subjects.push(updated_subject)
    });

    console.log("updated subjects", updated_subjects);
    setUpdatedSubjects(updated_subjects);
  }, [subjects, subjectAllocationList]);

  const studentClassesTableColumns = [
    {
      title: "Subject class ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Subject",
      dataIndex: ["name"],
      key: "name",
    },
    {
      title: "Subject Teacher",
      dataIndex: ["teacher_name"],
      key: "id",
    },
    {
      title: "View Timetable",
      dataIndex: ["time_table"],
      render: (_, record) => (
        <a href={`/timetable`} rel="noopener noreferrer">
          <EyeFilled style={{ fontSize: 30, color: "green" }} />
        </a>
      ),
    },
  ];

  console.clear()
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h3>My subjects</h3>
      </div>
      <Divider type={"horizontal"} />
      <Alert
        closable={true}
        className={"mb-2 py-2 rounded-1"}
        showIcon={true}
        type={"info"}
        message="The following are the subjects that you were assigned."
      />

      <Table
        dataSource={updatedSubjects}
        columns={studentClassesTableColumns}
      />
    </>
  );
};

export default StudentSubjectsList;
