import { Card, Input, Form, Table, Button, Space, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import "./stamp.css";
import authenticationService from "../../../services/authentication.service";
import studentResultsService from "../../../services/student-results.service";
import schoolService from "../../../services/school-service";
import { PrinterOutlined } from "@ant-design/icons";
import { apiUrl } from "../../../http-common";
import authHeader from "../../../services/auth-header";
import institutionService from "../../../services/institution.service";
import "./report.css";
// import { InstitutionLogo } from "../../../common";
import getGradingScaleByLevelId from "../../../services/grade-scale.service";
import useToken from "../../../hooks/useToken";
import studentClassService from "../../../services/student-class.service";

const CurrentReport = ({ studentUserId }) => {
  const [resultsData, setResultsData] = useState([]);
  const [studentDataa, setStudentDataa] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [institutionName, setInstitutionName] = useState();
  const [address, setAddress] = useState();
  const [logoUrl, setLogoUrl] = useState();
  const [randomHeadPrincipalComment, setRandomHeadPrincipalComment] =
    useState("");
  const [loading, setLoading] = useState(true);
  const [studentName, setStudentName] = useState("");
  const [level, setlevel] = useState("");
  const [studentClassName, setStudentClassName] = useState("");

  const [
    studentGradePercentageAverageGrade,
    setStudentGradePercentageAverageGrade,
  ] = useState("");
  const [
    studentGradePercentageAverageMark,
    setStudentGradePercentageAverageMark,
  ] = useState(0);
  let date = new Date(); 

  const tenantId = authenticationService.getUserTenantId();
  
  const fetchInstitutionLogo = async () => {
    try {
      const response = await institutionService.getLogoByTenantId(tenantId);
      if (response?.status === 200) {
        const logo = response?.data?.url;
        setLogoUrl(logo);
      }
    } catch (e) {}
  };


  async function fetchStudentClassInfo() {
    try {
      const studentUserId = authenticationService.getUserId();
      const studentClassroomResponse =
        await studentClassService.getStudentClassByStudentUserId(studentUserId);
      if (studentClassroomResponse?.status === 200) { 
        // console.log("class", studentClassroomResponse.data.classroom.name)
        setStudentClassName(studentClassroomResponse?.data?.classroom?.name);
      }
    } catch (e) {
      return "";
    }
  }

  const fetchStudentResults = async () => {
    try {
      const response = await studentResultsService.getCurrentTermResults(
        studentUserId
      );

      if (response.status === 200) {
        setResultsData(response.data[1]);
        // console.log("results data", response.data[1])
        // console.log("student data", response.data[0])
        setStudentDataa(response.data[0]);
      } else {
        console.log("Request was not successful. Status:", response.status);
      }
    } catch (error) {
      console.error("Error occured during fetching academic years:", error);
    }
  };

  const fetchSchoolData = async () => {
    try {
      const schoolResponse = await schoolService.getSchoolbytenantId(tenantId);

      if (schoolResponse.status === 200) {
        setSchoolData(schoolResponse.data);
        setInstitutionName(schoolResponse?.data?.institution_name);
        setAddress(schoolResponse?.data.address);
      } else {
        console.log(
          "Request was not successful. Status:",
          schoolResponse.status
        );
      }
    } catch (error) {
      console.error("Error occured during fetching academic years:", error);
    }
  };

  useEffect(() => {
    fetchInstitutionLogo();
    fetchStudentResults();
    fetchSchoolData();
  }, []);

  const tableColumns = [
    {
      title: "SUBJECT",
      dataIndex: ["subject"],
      key: "1",
      render: (text) => <span>{text.toUpperCase()}</span>,
      onHeaderCell: () => {
        return {
          style: {
            background: "green",
            color: "white",
          },
        };
      },
    },
    {
      title: "MARK",
      dataIndex: "total_mark",
      key: "2",
      onHeaderCell: () => {
        return {
          style: {
            background: "green",
            color: "white",
          },
        };
      },
    },
    {
      title: "GRADE",
      dataIndex: "grade",
      key: "3",
      onHeaderCell: () => {
        return {
          style: {
            background: "green",
            color: "white",
          },
        };
      },
    },
    {
      title: "TEACHER'S COMMENT",
      dataIndex: "comment",
      key: "4",
      onHeaderCell: () => {
        return {
          style: {
            background: "green",
            color: "white",
          },
        };
      },
    },
  ];

  const CustomTable = ({ resultsData }) => (
    <div className="col-12">
      <Table
        className="table-responsive table-layout pb-2"
        dataSource={resultsData}
        columns={tableColumns}
        pagination={false}
        bordered={true}
        locale={{
          emptyText: "Results Not Yet Available. Please consult your teacher.",
        }}
        loading={() => "Report Not Yet Available"}
        rowClassName={() => "custom-row"}
      />
    </div>
  );

  const currentComponentRef = useRef();
  const handlePrintCurrentResults = useReactToPrint({
    content: () => currentComponentRef.current,
    documentTitle: `Report for ${
      studentDataa && studentDataa?.student?.firstName
    }`,
  });

  const gradeComments = {
    "75 - 100": [
      "Outstanding! Keep maintaining this exceptional standard.",
      "Your performance is remarkable. Excellent work!",
      "Truly excellent—this is the result of hard work and dedication.",
      "A stellar result! Keep striving for excellence.",
      "Exceptional achievement. Continue challenging yourself.",
      "You’ve mastered this—well done!",
      "Top-notch work! You’re a role model for others.",
      "Impressive work! Keep aiming high.",
      "This is outstanding. Keep up the excellent effort.",
      "Fantastic achievement! Maintain this momentum.",
    ],
    "50 - 74": [
      "Good effort! Aim for even greater heights.",
      "Well done—you're improving steadily.",
      "Solid performance. Keep building on this foundation.",
      "You're on the right track. Keep pushing forward.",
      "Nice work! A little more effort will go a long way.",
    ],
    "25 - 49": [
      "You’re making progress. Don’t stop now.",
      "A fair attempt, but there's room for improvement.",
      "Focus on your weaker areas to achieve even more.",
      "Keep practicing and success will follow.",
      "It’s a step in the right direction. Keep going!",
    ],
    "0 - 24": [
      "Don’t give up—every failure is a lesson.",
      "Keep working hard. Growth comes with persistence.",
      "You’re capable of much more. Stay determined.",
      "Focus on understanding the concepts more deeply.",
      "It’s okay to struggle. Keep moving forward.",
    ],
  };

  function getRandomComment(grade) {
    for (const range in gradeComments) {
      const [min, max] = range.split(" - ").map(Number);
      if (grade >= min && grade <= max) {
        const feedbackList = gradeComments[range];
        return feedbackList[Math.floor(Math.random() * feedbackList.length)];
      }
    }
    return "";
  }

  function hashString(input) {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      const char = input.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0;
    }
    return Math.abs(hash);
  }

  function getDeterministicRandomComment(name, level, grade) {
    const studentKey = `${name?.toLowerCase()}-${level?.toLowerCase()}`;
    const hash = hashString(studentKey);
    for (const range in gradeComments) {
      const [min, max] = range.split(" - ").map(Number);
      if (grade >= min && grade <= max) {
        const feedbackList = gradeComments[range];
        const index = hash % feedbackList.length;
        return feedbackList[index];
      }
    }
    return "";
  }

  useEffect(() => {
    const subs_length = resultsData.length;
    const average_grade_percentage = (
      resultsData
        .map(({ total_mark }) => parseFloat(total_mark))
        .reduce((total_mark, totalMark) => (totalMark += total_mark), 0) /
      subs_length
    ).toFixed(2);

    setStudentGradePercentageAverageMark(average_grade_percentage);
  }, [resultsData]);

  const random_comment = getRandomComment(studentGradePercentageAverageMark);
  useEffect(() => {
    setRandomHeadPrincipalComment(random_comment);
  }, [randomHeadPrincipalComment, random_comment]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchStudentClassInfo(),
        fetchInstitutionLogo(),
        fetchStudentResults(),
        fetchSchoolData(),
      ]);
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setStudentName(`${studentDataa?.firstName} ${studentDataa?.lastName}`);
    setlevel(studentDataa?.student?.level);
  }, [studentDataa]);
 
  const isECDA = (levelOrGrade) => {
    return (
      !(levelOrGrade?.trim().toLowerCase().indexOf("ecd") === -1) &&
      !(levelOrGrade?.trim().toLowerCase().indexOf("a") === -1)
    );
  };

  const isGradeFoundInOneThroughFive = (levelOrGrade) => {
    // console.log("graddddde", levelOrGrade)
    return (
      !(levelOrGrade?.trim().toLowerCase().indexOf("grade") === -1) &&
      /grade\s([1-5])/.test(levelOrGrade.trim().toLowerCase())
    );
  };

  const isGradeSixOrSeven = (levelOrGrade) => {
    // console.log("graddddde", levelOrGrade)
    return (
      !(levelOrGrade?.trim().toLowerCase().indexOf("grade") === -1) &&
      /grade\s([6-7])/.test(levelOrGrade.trim().toLowerCase())
    );
  };

  const isECDB = (levelOrGrade) => {
    return (
      !(levelOrGrade?.trim().toLowerCase().indexOf("ecd") === -1) &&
      !(levelOrGrade?.trim().toLowerCase().indexOf("b") === -1)
    );
  };

  const isAnyOtherGrade = (levelOrGrade) => {
    return !(isECDA(levelOrGrade) || isECDB(levelOrGrade) || isGradeFoundInOneThroughFive(levelOrGrade) || isGradeSixOrSeven(levelOrGrade))
  }

  const gradesOneThroughFiveComments = [
    { min: 45, max: 50, comment: "Excellent work! Keep it up!" },
    { min: 40, max: 44.99, comment: "Great effort! You're doing well!" },
    { min: 35, max: 39.99, comment: "Good job! Practice a bit more." },
    { min: 30, max: 34.99, comment: "Keep going! You’re improving." },
    { min: 25, max: 29.99, comment: "Nice try! Focus on the tough spots." },
    { min: 20, max: 24.99, comment: "You’re trying! Let’s work on this." },
    { min: 15, max: 19.99, comment: "Keep practicing! You’ll get there." },
    { min: 10, max: 14.99, comment: "Don’t give up! You’re learning." },
    { min: 0, max: 9.99, comment: "Mistakes happen. Let’s improve!" },
  ];

  const ecdAComments = [
    { min: 9, max: 10, comment: "Amazing job! You did it!" },
    { min: 8, max: 8.99, comment: "Great work! You’re almost there!" },
    { min: 7, max: 7.99, comment: "So proud of you! Keep going!" },
    { min: 6, max: 6.99, comment: "Nice effort! You're doing really well!" },
    { min: 5, max: 5.99, comment: "Good try! Let's keep practicing!" },
    { min: 4, max: 4.99, comment: "You’re learning! Let’s keep trying!" },
    { min: 3, max: 3.99, comment: "Good job! Keep going, you'll get better!" },
    { min: 2, max: 2.99, comment: "You're doing your best! Keep practicing!" },
    { min: 1, max: 1.99, comment: "Great effort! Let’s keep going!" },
    {
      min: 0,
      max: 0.99,
      comment: "Everyone starts somewhere. You’re learning!",
    },
  ];

  const ecdBComments = [
    { min: 18, max: 20, comment: "Fantastic work! You're a superstar!" },
    { min: 16, max: 17.99, comment: "Great job! You're doing really well!" },
    {
      min: 14,
      max: 15.99,
      comment: "Excellent! Keep it up, you’re improving!",
    },
    {
      min: 12,
      max: 13.99,
      comment: "Nice work! You’re getting stronger every day!",
    },
    { min: 10, max: 11.99, comment: "Good job! You’re doing awesome!" },
    { min: 8, max: 9.99, comment: "Well done! Let’s keep going!" },
    { min: 6, max: 7.99, comment: "Great effort! You’re on the right track!" },
    { min: 4, max: 5.99, comment: "You’re doing your best! Keep practicing!" },
    { min: 2, max: 3.99, comment: "Nice try! Keep up the good work!" },
    {
      min: 0,
      max: 1.99,
      comment: "You’re learning! Every try makes you better!",
    },
  ];

  function getComment(mark, dataObject) {
    const commentObject = dataObject.find(
      ({ min, max }) => mark >= min && mark <= max
    );
    return commentObject ? commentObject.comment : "..";
  }

  const getGradeComment = (level, grade, comment) => {
    if (isGradeFoundInOneThroughFive(level)) {
      return getComment(parseFloat(grade), gradesOneThroughFiveComments);
    } else if (isECDA(level)) {
      return getComment(parseFloat(grade), ecdAComments);
    } else if (isECDB(level)) {
      return getComment(parseFloat(grade), ecdBComments);
    } else {
      return comment;  
    }
  }; 

  return loading ? (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <Spin size="large" />
    </div>
  ) : (
    <>
      <div className="d-flex justify-content-between mt-2 mb-4">
        <h3></h3>
        <Button
          className="border-0 px-3 text-white"
          style={{ background: "#3786DB" }}
          onClick={handlePrintCurrentResults}
        >
          <Space>
            <PrinterOutlined />
            Print report card
          </Space>
        </Button>
      </div>
      <div
        className="container"
        style={{
          paddingInline: 0,
        }}
        ref={currentComponentRef}
      >
        <div className="school_report">
          <h2 className="school_report_heading" id="school_report_heading">
            {`${studentDataa && studentDataa?.student?.firstName} ${
              studentDataa && studentDataa?.student?.lastName
            }`}
            's report card
          </h2>
          <div className="school_report_main_data" id="content">
            <div className="school_logo_and_name_container">
              <img
                src={logoUrl}
                alt={`${institutionName?.toUpperCase()} logo`}
              />
              
              <h3 className="school_logo_and_name_container">
                {institutionName?.toUpperCase()}
              </h3>
            </div>
            <div className="school_report__details_container">
              <h3>REPORT CARD</h3>
              <div className="data">
                <span>
                  <b>Name of student:&nbsp;</b>
                  {studentDataa &&
                    studentDataa?.student?.firstName +
                      " " +
                      studentDataa?.student?.lastName}
                </span>
                <span>
                  <b>Reg number:&nbsp;</b>
                  {studentDataa && studentDataa?.student?.regNumber}
                </span>
                {studentDataa?.student && (
                  <span>
                    <b>Term:&nbsp;</b>
                    {studentDataa.term}
                  </span>
                )}
                {studentDataa?.student && (
                  <span>
                    <b>Year:&nbsp;</b>
                    {studentDataa.academic_year}
                  </span>
                )}
                {studentDataa?.student && (
                  <span>
                    <b>Level/Grade:&nbsp;</b>
                    {`${studentDataa.student.level}${studentClassName}`}
                  </span>
                )}
              </div>
            </div>

            {resultsData.length > 0 ? (
              <>
                <div className="school_report_details_and_grading_system_container">
                  <div className="school_report_details_and_grading_system_container__left">
                    <div className="school_report_details_and_grading_system_container__left_1">
                      {studentDataa && (
                        <h3>
                          <span>SUBJECT</span>
                          <span>MARK</span>
                          <span>GRADE</span>
                          <span>TEACHER'S COMMENT</span>
                        </h3>
                      )}
                      <div className="results">
                        {resultsData &&
                          resultsData.map(
                            ({ total_mark, comment, grade, subject }, i) => (
                              <p key={i}>
                                <span>{subject}</span>
                                <span>{total_mark}</span>
                                <span>{grade}</span>
                                <span>{getGradeComment(level, total_mark, comment)}</span>
                              </p>
                            )
                          )}
                      </div>
                    </div>
                    <div className="school_report_details_and_grading_system_container__left_3">
                      <h3>
                        <span className="grade_percent_average">
                          GRADE AVERAGE
                        </span>
                        <span className="score">MARK</span>
                        {/* <span className="grade">GRADE</span> */}
                      </h3>
                      <div className="data">
                        <span className="data__grade_percent_average">
                        {
                            isAnyOtherGrade(level) ? 
                            'Average percentage(%)' 
                            :
                            'Average mark'
                          } 
                        </span>
                        <span className="data__score">
                          {studentGradePercentageAverageMark}
                        </span>
                        {/* <span className="data__grade">
                      {studentGradePercentageAverageGrade}
                    </span> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="school_stamp_and_head_comment_container">
                  <div className="head_comment">
                    <h3>OVERALL COMMENT</h3>
                    <p>
                      {getDeterministicRandomComment(
                        studentName,
                        level,
                        studentGradePercentageAverageMark
                      )}
                    </p>
                  </div>
                  <div className="school_stamp">
                    <span>This is an authorized digital stamp</span>
                    <p>{date.toDateString()}</p>
                    <p>{address}</p>
                  </div>
                </div>
              </>
            ) : (
              <div className="school_report_details_and_grading_system_container__left_1 results_not_yet_available">
                <h3>RESULTS NOT YET AVAILABLE</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentReport;
