import { useEffect, useState } from "react";
import useToken from "../../../hooks/useToken";
import { apiUrl } from "../../../http-common";
import authenticationService from "../../../services/authentication.service";
import parentService from "../../../services/parent.service";
import studentService from "../../../services/student.service";
import getSeconds from "date-fns/fp/getSeconds/index";
import "./all_parents.css";
import InstitutionService from "../../../services/institution.service.js";

const AllParents = () => {
  const { token, setToken } = useToken();
  const [allStudents, setAllStudents] = useState([]);
  const [reducedStudentsData, setReducedStudentsData] = useState([]);
  const [schoolName, setSchoolName] = useState("");

  const school_id = authenticationService.getUserTenantId();

  const customisedSMS = (childFullName, schoolName) => `Dear Parent

    Your child ${childFullName} is now available on the ${schoolName} E-School Platform.
    To access the report, please Login through this link https://eschools-zw.app.brainstake.tech

    Use this registration number for your child REG_NUMBER. Enter the word password in the password field to successfully login.

    For any enquiries contact:
    +263785364779
    +263771762318 `;

  const get_institution_name = async () => {
    const response = await fetch(`${apiUrl}institutions/${school_id}/`, {
      headers: apiHeaders,
    });
    const data = await response.json();
    return data.institution_name;
  };

  useEffect(() => {
    get_institution_name().then((response) => {
      setSchoolName(response);
    });
  }, []);

  // useEffect(() => {
  //   console.log("school name", schoolName);
  // }, [schoolName]);

  const apiHeaders = {
    Authorization: `Bearer ${token}`,
  };

  const get_student_details = async (student_id) => {
    const response = await fetch(`${apiUrl}students/${student_id}/`, {
      headers: apiHeaders,
    });
    const data = await response.json();

    // console.log("DATATAAT", data);
    const individual_student_reduced_data = {
      studentParentPhoneNumber: data.parentPhoneNumber,
      customisedSMSText: customisedSMS(
        `${data.user.firstName} ${data.user.lastName}`,
        schoolName
      ),
      fullName: `${data.user.firstName} ${data.user.lastName}`,
      regNumber: data.user.username,
      institutionName: schoolName,
    };
    // console.log(
    //   "individual reduced student data",
    //   individual_student_reduced_data
    // );
    return individual_student_reduced_data;
  };

  const get_all_students_by_school_id = async () => {
    const response = await fetch(
      `${apiUrl}students/get-all-students-by-institution-id/${school_id}/`,
      {
        headers: apiHeaders,
      }
    );
    const data = await response.json();
    const student_ids = data.map(({ id }) => id);
    const reduced_student_data = [];
    student_ids.forEach((id) => {
      get_student_details(id).then((response) => {
        // console.log("responseeee", response);
        if (response.studentParentPhoneNumber) {
          reduced_student_data.push(response);
        }
      });
    });

    // console.log("Reduced student data", reduced_student_data);
    setAllStudents(reduced_student_data);
  };

  // useEffect(() => {
  //   console.log("reduced student data", reducedStudentsData);
  // }, [reducedStudentsData]);

  useEffect(() => {
    get_all_students_by_school_id();
  }, []);

  // useEffect(() => {
  //   console.log("All students", allStudents);
  //   console.log("School id", school_id);
  // }, [allStudents, school_id]);

  const exportToCSV = () => {
    const headers = Object?.keys(allStudents[0]);
    const rows = allStudents.map((row) =>
      headers.map((field) => JSON.stringify(row[field] || ""))
    );

    const csvContent = [
      headers.join(","),
      ...rows.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // console.clear()

  return (
    <div className="all_parents_container">
      <button onClick={exportToCSV}>Export student-parent data to CSV</button>
    </div>
  );
};

export default AllParents;
