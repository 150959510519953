import { EditOutlined } from '@ant-design/icons'
import { Button, Divider, Input, message, Form, Card } from 'antd'
import React, { useState } from 'react'
import authenticationService from '../../services/authentication.service'

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmationPassword, setConfirmationNewPassword] = useState('');
    const [changePasswordBtnLoading, setChangePasswordBtnLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const handleChangePassword = async () => {
        if (oldPassword === '') {
            message.warning('Please enter the current password.');
            return -1;
        }

        if (newPassword === '') {
            message.warning('Please enter the new password.');
            return -1;
        }

        if (confirmationPassword === '') {
            message.warning('Please enter the confirmation password.');
            return -1;
        }

        if (newPassword !== confirmationPassword) {
            message.error('Passwords do not match.');
            return -1;
        }

        if (confirmationPassword.length < 8) {
            message.info('Your password must contain at least 8 characters.');
            return -1;
        }

        try {
            setChangePasswordBtnLoading(true);
            const response = await authenticationService.verifyPassword({
                username: authenticationService.getUsername(),
                password: oldPassword
            })
            if (response?.status === 200) {
                const res = await authenticationService.changePassword({
                    username: authenticationService.getUsername(),
                    password: confirmationPassword
                })
                if (res?.status === 200) {
                    message.success('Password updated successfully.');
                    setChangePasswordBtnLoading(false);
                    setTimeout(authenticationService.logout, 1900)
                }
            }

        } catch (e) {
            if (e.response.status === 403) {
                message.error('Enter the correct current password.')
                setChangePasswordBtnLoading(false)
                return -1
            }
        }
    }

    return (
        <div className={"overflow-x-hidden"}>
            <div className='d-flex justify-content-between align-items-center'>
                <h3>Change Password</h3>
            </div>
            <Divider type={"horizontal"} />

            <div className='d-flex justify-content-center'>
                <Card size='default' className='w-75'>
                    <Form layout={"vertical"} form={form} onFinish={handleChangePassword}>
                        <Form.Item
                            label="Current password"
                            name="oldPassword"
                            rules={[{ required: true, message: 'Current password is required!' }]}
                        >
                            <Input.Password
                                placeholder='Current password'
                                size={"large"}
                                onChange={e => setOldPassword(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            label="New password"
                            name="newPassword"
                            rules={[{ required: true, message: 'New password is required!' }]}
                        >
                            <Input.Password
                                placeholder='new password'
                                size={"large"}
                                onChange={e => setNewPassword(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Repeat password"
                            name="confirmationPassword"
                            rules={[{ required: true, message: 'Repeat password is required!' }]}
                        >
                            <Input.Password
                                placeholder='Repeat password'
                                size={"large"}
                                onChange={e => setConfirmationNewPassword(e.target.value)}
                            />
                        </Form.Item>
                        <Button
                            className='text-light'
                            style={{ background: '#39b54a' }}
                            size={"large"}
                            htmlType="submit"
                            icon={<EditOutlined />}
                            loading={changePasswordBtnLoading}
                            block
                        >
                            Change password
                        </Button>
                    </Form>
                </Card>
            </div>


        </div>
    )
}

export default ChangePassword