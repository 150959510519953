import {Button, Form, Input, message, Modal} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {useState} from "react";
import StudentService from "../../../services/student.service";
import { useLocation, useNavigate } from "react-router-dom";

const UploadStudentBirthCertificate = ({open, close, id}) => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [formData, setFormData] = useState(''); 
    const [formInputError, setFormInputError] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    const handleSubmit = (e) => {
        e.preventDefault();
 
        const form = document.querySelector('#document-form');
        const data = new FormData(form);

        if(formData.length === 0) {
            message.error('Please select a file')  
            setFormInputError(true)
            setTimeout(() => setFormInputError(false), 2000)
            return
        } 

        if(e.target.parentElement?.querySelector('#upload_birt_cert_input')?.files[0]?.type !== "application/pdf") {
            message.error('Can only upload pdf files') 
            return
        } 
 
        if(e.target.parentElement?.querySelector('#upload_birt_cert_input')?.files[0]?.size >= 5250000) {
            message.error('File size should be 5MB or less') 
            return
        }

        try {
            StudentService.uploadStudentDocument(id, data) 
            message.success('Document uploaded successfully.')
            setLoading(true);
            setDisabled(false);
            close() 
            navigate(location.pathname, {replace: true}) 
        } catch (e) {
            message.error(e.response.data)
            setLoading(false);
            setDisabled(false);
        }
    }

    return (
        <Modal
            open={open}
            onCancel={() => close()}
            cancelButtonProps={{
                className: 'd-none'
            }}
            okButtonProps={{
                className: 'd-none'
            }}
            destroyOnClose={true}
            className='upload_student_birth_certificate_modal'
        >
            <Form
                layout={"vertical"}
                className='pt-4'
                method='post'
                encType='multipart/form-data'
                onSubmitCapture={handleSubmit}
                id='document-form'
            >
                <Form.Item
                    label='Select birth certificate'
                    help="Select the student's birth certificate."
                    className='upload_student_birth_certificate_input'
                >
                    <Input
                        type='file'
                        size={"large"}
                        name='document'
                        id='upload_birt_cert_input'
                        onChange={(e) => {
                            setFormData(e.target.value)

                            if(e.target.value.length > 0) {
                                setDisabled(false)
                            } else if(e.target.value.length === 0) {
                                setDisabled(true)
                            }
                            
                        }}
                        value={formData}
                        className={`${formInputError ? 'error_state' : ''}`}
                    />
                    <Button
                        className='clear_birth_certificate_upload'
                        onClick={() => { 
                            message.destroy()
                            setFormData('') 
                            setFormInputError(false)
                            setLoading(false)
                        }}  
                    >+</Button>
                </Form.Item>
                <Button
                    size={"large"}
                    block={true}
                    className='border-0 px-3 text-white'
                    style={{background: '#39b54a'}}
                    icon={<UploadOutlined />}
                    htmlType={"submit"}
                    loading={loading}
                    disabled={disabled}
                >
                    Upload
                </Button>
            </Form>
        </Modal>
    )
}

export default UploadStudentBirthCertificate;