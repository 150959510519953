import {ArrowLeftOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {useNavigate} from "react-router-dom";

const BackButton = () => {
    const navigate = useNavigate();
    return ( 
        <Button
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate(-1)}
            className='text-dark shadow-none py-3 mt-5 mb-3 back_button'
        >Back</Button>  
    )
}

export default BackButton;