import { Button, DatePicker, Form, Input, Modal, message } from "antd";
import { useState } from "react";
import authenticationService from "../../../services/authentication.service";
import { PlusOutlined } from "@ant-design/icons";
import suspensionService from "../../../services/suspension-service";
import TextArea from "antd/es/input/TextArea";
import { refreshPage } from "../../../common";
import { isNullOrUndef } from "chart.js/helpers";

const SuspendStudent = ({ open, close, studentId, fullname }) => {

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [formData, setFormData] = useState({
    reason: "",
    suspension_date: "",
    return_date: "",
    institution: "",
  });

  const institution = authenticationService.getUserTenantId();

  const handleFormChange = (fieldName, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const handleFormSubmit = async () => {
    let susp_date = "";
    if (formData.suspension_date !== "") {
      let dateObj = new Date(formData.suspension_date);
      if (!isNaN(dateObj.getTime())) { // Check if dateObj is a valid Date
        susp_date = dateObj.toLocaleDateString();
      }
    }
    console.log(susp_date);

    let ret_date = "";
    if (formData.return_date !== ""){
      let dateObj = new Date(formData.return_date);
      if (!isNaN(dateObj.getTime())){
        ret_date = dateObj.toLocaleDateString();
      }
    }
    console.log(ret_date)
   
    let current_date = new Date().toLocaleDateString();
    console.log(current_date)

    if (susp_date === "") {
      message.warning("Suspension date should not be empty");
    } 
    else if(ret_date === ""){
      message.warning("Return date should not be empty");
    }
    else if (susp_date < current_date) {
      message.warning("Suspension date should not be before Today's date");
    } else if (ret_date !== "" && ret_date < susp_date) {
      message.warning("Return date should not be before Suspension date");
    } else if (ret_date !== "" && ret_date === susp_date) {
      message.warning("Return date should not be equal to Suspension date");
    }
    else {

      console.log(susp_date)
      console.log(ret_date)
      try {
        setLoading(true);
        setDisabled(true);

        const { reason, suspension_date, return_date } = formData;
        const student = studentId

        const data = {
          institution,
          student,
          reason,
          suspension_date,
          return_date,
        };

        const response = await suspensionService.suspend(data, studentId);

        if (response.status === 201) {
          message.success("Student Suspended Successfully");
          // refreshPage();
          close();
          setLoading(false);
          setDisabled(false);
        } else {
          setLoading(false);
          setDisabled(false);
          message.error("An error occurred, please check your network.");
        }
      } catch (error) {
        console.log("Error occurred:", error);
        setLoading(false);
        setDisabled(false);
        message.error("An error occurred. Please check your network connection.");
      }
    }
  };


  return (
    <>
      <Modal
        title="Suspend Student"
        visible={open}
        onCancel={close}
        width={800}
        okButtonProps={{
          className: "d-none",
        }}
        cancelButtonProps={{
          className: "d-none",
        }}
      >
        <Form layout="vertical">
          <div className="row">
            <Form.Item label="Student name">
              <Input
                size="large"
                value={fullname}
                style={{ backgroundColor: "grey" }}
              />
            </Form.Item>
            <Form.Item label="Reason for suspension">
              <TextArea
                placeholder="type reason for suspension here"
                size="large"
                style={{ height: "150px" }}
                onChange={(e) =>
                  handleFormChange("reason", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="Suspension date">
              <DatePicker
                placeholder="suspension date"
                size="large"
                onChange={(date, datestring) => handleFormChange("suspension_date", datestring)}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item label="Return date">
              <DatePicker
                placeholder="return date"
                size="large"
                onChange={(date, datestring) => handleFormChange("return_date", datestring)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>

          <Button
            type="primary"
            size="large"
            loading={loading}
            icon={<PlusOutlined />}
            disabled={disabled}
            block
            onClick={handleFormSubmit}
          >
            Suspend Student
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default SuspendStudent;
