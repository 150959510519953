import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  DatePicker,
  message,
  Select,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import ChangePasswordForm from "../../../common/ChangePasswordForm";
import "../../../css/RegisterParent.css";
import { apiUrl } from "../../../http-common";
import half_logo from "../../../Assets/images/half_logo.png";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const RegisterParent = () => {
  const { studentid } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dateString, setDateString] = useState(null);
  const [nowdate, setDate] = useState(formatDate(new Date()));
  const [institution, setInstitution] = useState({ name: "", logo_url: "" });
  const [error, setError] = useState(null);
  const [imageExists, setImageExists] = useState(true);

  // ===============HANDLE DATE CHANGE============================
  const [inputDate, setInputDate] = useState("");
  const [formattedDate2, setFormattedDate] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const handleYearChange = (e) => {
    // setYear(e.target.value);
    const value = e.target.value;
    // Allow only 4-digit numbers
    if (/^\d{0,4}$/.test(value)) {
      setYear(value);
    }
  };

  const uniqueStrings = new Set();

  function generateUniqueString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result;

    do {
      result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
    } while (uniqueStrings.has(result)); // Regenerate if the string already exists

    uniqueStrings.add(result); // Add the new unique string to the set
    return result;
  }

  function generateUniqueStringWithTimestamp(length) {
    const baseString = generateUniqueString(length); // Use the previous function
    return baseString + Date.now();
  }

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
    // Allow only digits and limit to 2 characters
    const value = e.target.value;
    if (/^\d{0,2}$/.test(value)) {
      setMonth(value);
    }
  };

  const handleDayChange = (e) => {
    setDay(e.target.value);
  };

  useEffect(() => {
    const fetchInstitution = async () => {
      try {
        const response = await fetch(
          `${apiUrl}parents/institution/${studentid}`
        );
        if (!response.ok) {
          console.log("failed to fetch logo and school name");
        }
        const data = await response.json();
        setInstitution(data);
        console.log("logo and school data ======================");
        console.log(data);
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    };

    fetchInstitution();
  }, []);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setDateString(dateString);
  };

  // Example usage:
  const currentDate = new Date();
  const formattedDate = formatDate(currentDate);

  const handleImageError = () => {
    setImageExists(false);
  };

  function generateRandomFiveDigitNumber() {
    return Math.floor(10000 + Math.random() * 90000);
  }

  const handleSubmit = async (values) => {
    console.log("--------------FOM VALUES-----------------------");
    setLoading(true);
    const formattedDatenew = `${year}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`;
    console.log(values);
    const payload = {
      user: {
        firstName: values.parentName,
        lastName: values.lastName,
        middleNames: values.middleName || "",
        role: "PARENT",
        gender: values.gender,
        email: `${generateUniqueStringWithTimestamp(7)}@placefolderemail.com`,
        phoneNumber: values.parentNumber,
        password: generateRandomFiveDigitNumber(),
      },
      address: values.nationalIdNumber,
      nationalId: values.nationalIdNumber,
      parentNumber: values.parentNumber,
      childDateOfBirth: formattedDatenew,
      childLastName: values.childLastName,
      studentID: studentid,
    };

    console.log(payload);

    try {
      const response = await axios.post(`${apiUrl}parents/`, payload);
      if (response.status === 200 || response.status === 201) {
        message.success("Parent registration successful! You can log in now");
        // Optionally, redirect or clear form
        form.resetFields();
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        const errorMessage = error.response.data.error;
        console.log("Error during registration:", error);
        message.error(errorMessage);
      }
    }
  };

  return (
    <div className="register-parent-container">
      <div className="header">
        <h3 className="">Complete Parent Registration</h3>
        {/* {imageExists && institution.logo_url ? (
                    <img
                        src={institution.logo_url}
                        alt={`${institution.name} Logo`}
                        style={{ width: '10%', height: '50px' }}
                        onError={handleImageError}
                    />
                ) : (
                    <img
                        src={half_logo}
                        alt={`${institution.name} Logo`}
                        style={{ width: '10%', height: 'auto' }}
                        onError={handleImageError}
                    />
                )} */}
        <h3>{institution.name}</h3>
      </div>
      <Divider />

      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <Card hoverable className="profile-card">
              <Form.Item
                label="Parent's First Name"
                name="parentName"
                rules={[
                  { required: true, message: "Please input your parent name!" },
                ]}
              >
                <Input size="large" placeholder="Enter First Name" />
              </Form.Item>
              <Form.Item label="Parent's Middle Name" name="middleName">
                <Input size="large" placeholder="Enter Middle Name" />
              </Form.Item>
              <Form.Item
                label="Parent's Last Name"
                name="lastName"
                rules={[
                  { required: true, message: "Please input your last name!" },
                ]}
              >
                <Input size="large" placeholder="Enter Last Name" />
              </Form.Item>

              <Form.Item
                label="Parent's Gender"
                name="gender"
                rules={[
                  { required: true, message: "Please select your gender!" },
                ]} // Optional validation
              >
                <Select size="large" placeholder="Select Gender">
                  <Select.Option value="MALE">Male</Select.Option>
                  <Select.Option value="FEMALE">Female</Select.Option>
                </Select>
              </Form.Item>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="details-card">
              <Form.Item label="Parent's Address" name="address">
                <Input placeholder="Enter Address" />
              </Form.Item>
              {/* <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input  size="large" type='email' placeholder="Enter Email" />
                            </Form.Item> */}
              <Form.Item
                label="National ID Number"
                name="nationalIdNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your national ID number!",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter National ID Number" />
              </Form.Item>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="details-card">
              <Form.Item
                label="Phone Number (Use same number which received SMS). Start with 263..."
                name="parentNumber"
                rules={[
                  {
                    required: true,
                    message: "Please input your number eg 263777777777",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Please input your number eg 263777777777"
                />
              </Form.Item>
              {/* ----------------------------------------- */}
              <Form.Item label="Child's Date Of Birth" required>
                <Input
                  value={year}
                  required
                  onChange={handleYearChange}
                  size="large"
                  placeholder="YYYY"
                  style={{ width: "30%", marginRight: "5%" }}
                />
                <Input
                  value={month}
                  required
                  onChange={handleMonthChange}
                  size="large"
                  placeholder="MM"
                  style={{ width: "30%", marginRight: "5%" }}
                />
                <Input
                  value={day}
                  required
                  onChange={handleDayChange}
                  size="large"
                  placeholder="DD"
                  style={{ width: "30%" }}
                />
              </Form.Item>
              {/* ----------------------------------------- */}
              {/* <Form.Item
                                label="Child's Date Of Birth"
                                name="childDateOfBirth"
                                rules={[{ required: true, message: 'Please input your child date of birth!' }]}
                            >
                                <DatePicker 
                                    value={nowdate} 
                                    onChange={onChange} 
                                    size="large" 
                                    format="YYYY-MM-DD" 
                                    style={{ marginTop: '10px', width: '100%' }} // Styling
                                /> 
                            </Form.Item> */}
              <Form.Item
                label="Child's Last Name"
                name="childLastName"
                rules={[
                  {
                    required: true,
                    message: "Please input your child last name!",
                  },
                ]}
              >
                <Input size="large" placeholder="Enter Child's Last Name" />
              </Form.Item>
            </Card>
          </div>
        </div>
        <div>
          By registering on this platform, you acknowledge and consent to the
          collection, processing, and secure storage of your and your child’s
          information for the purposes of school management and communication.
          Please review our{" "}
          <a href="https://eschools.brainstake.tech/terms.html" rel="noreferrer" target="_blank" >terms</a>
          &nbsp;and&nbsp;
          <a href="https://eschools.brainstake.tech/privacy.html" rel="noreferrer" target="_blank" >
            privacy policy
          </a>
          &nbsp;for further details.
        </div>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          size="large"
          loading={loading}
          block
          htmlType="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default RegisterParent;
