import { useState } from "react";
import HeroSection from "./hero_section";


const LandingPage = () => {


    return (
        <section className="landing_page">
            <div>
                <HeroSection />
            </div>
        </section>
    );

}

export default LandingPage;