import { Button, Divider, Space, Table, Tooltip } from "antd";
import { useLoaderData, useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import ClassroomService from "../../../services/classroom.service";
import AuthenticationService from "../../../services/authentication.service";
import { useEffect, useState } from "react";
import { apiUrl } from "../../../http-common";
import useToken from "../../../hooks/useToken";

export async function subjectClassStudentsLoader({ params }) {
  try {
    const studentsResponse = await ClassroomService.getStudentsByClassroomId(
      params?.id,
      AuthenticationService.getUserTenantId()
    );
    const classroomsResponse = await ClassroomService.getById(params?.id);

    return {
      students: studentsResponse?.data,
      classroom: classroomsResponse?.data,
    };
  } catch (e) {
    return [];
  }
}

const SubjectClassStudentsList = () => {
  const { students, classroom } = useLoaderData();
  const [levelId, setLevelId] = useState("");
  const [studentsByLevelId, setStudentsByLevelId] = useState([]);
  const { token, setToken } = useToken();

  useEffect(() => {
    setLevelId(classroom?.level?.id);
  }, [classroom?.level?.id]);

  useEffect(() => {
    console.log("level id", levelId);
    console.log("students by level id", studentsByLevelId);
  }, [studentsByLevelId, levelId]);

  useEffect(() => {
    const fetchStudentsByLevelId = async () => {
      const response = await fetch(
        `${apiUrl}students/get-students-by-level-id/${levelId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response", response);
      const students_by_level_id = await response.json();

      const reduced_data = students_by_level_id.map(({ user }) => user);

      setStudentsByLevelId(reduced_data);
    };

    if (levelId && token) {
      fetchStudentsByLevelId();
    }
  }, [levelId, token]);

  const navigate = useNavigate();

  const studentsTableColumns = [
    {
      title: "Reg number",
      dataIndex: "username",
      key: "registrationNumber",
    },
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstname",
      sorter: {
        compare: (a, b) => a.firstname < b.firstname,
      },
    },
    {
      title: "Middle name",
      dataIndex: "middleNames",
      key: "middlenames",
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastname",
      defaultSortOrder: "descend",
      sorter: {
        compare: (a, b) => a.lastname < b.lastname,
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      sorter: {
        compare: (a, b) => a.gender < b.gender,
      },
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      render: (record) => (
        <Tooltip title="View Current Results">
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => {
              console.log("record", record);
              navigate(`/teacher/class/current-term-report/${record?.id}/`);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  console.clear()
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h3>
          Students in {classroom?.level?.name} 
          {/* {classroom?.name} */}
        </h3>
      </div>

      <Divider type={"horizontal"} />

      <Table dataSource={studentsByLevelId} columns={studentsTableColumns} />
    </>
  );
};

export default SubjectClassStudentsList;
