import { Button, Divider, message, Space, Table, Tooltip, Tabs, Select, Spin } from "antd";
import { EyeFilled, EyeOutlined, PlusOutlined, SendOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import NewExamination from "./NewExamination";
import EndTermExamService from "../../../services/end-term-exam.service";
import AuthenticationService from "../../../services/authentication.service";
import ExaminationAnalytics from "./ExaminationAnalytics";
import SchoolTermServices from "../../../services/schoolTerm.services";
import teacherService from "../../../services/teacher.service";

export async function examinationsListLoader() {
    try {
        const tenantId = AuthenticationService.getUserTenantId();
        const response = await EndTermExamService.getAllByInstitutionId(tenantId);
        if (response?.status === 200) {
            return { endTermExams: response.data };
        }
    } catch (e) {
        return { endTermExams: [] }
    }
}

const AdminMarkExam = () => {
    const { endTermExams } = useLoaderData();
    const [teachers, setTeachers] = useState([]);
    const navigate = useNavigate();
    const [newExaminationModalState, setNewExaminationModalState] = useState(false);
    const [isPublished, setIsPublished] = useState(
        Array.isArray(endTermExams) && endTermExams.length > 0 ? endTermExams[0]?.is_published : undefined

    );
    const [publishResultsBtnLoader, setPublishResultsBtnLoader] = useState(false);
    const [terms, setTerms] = useState([]);
    const [examinations, setExaminations] = useState([]);
    const [isFetchingExaminations, setIsFetchingExaminations] = useState(true);


    useEffect(() => {
        //console.log("studentClass", studentClass)
        getData()
        
    },[]);

    const getData = () => {
        teacherService.getAllTeachersByInstitutionId(AuthenticationService.getUserTenantId())
            .then(res => {
                const teachersMapped = res.data?.map(teacher => {
                    const label = `${teacher?.user?.firstName} ${teacher?.user?.lastName} (${teacher?.user?.username})`
                    return { label: label, value: teacher?.id }
                })
                setTeachers(teachersMapped)
            })
            .catch(e => {
                console.log({ e })
            });
        }

    

        const examinationsListTableColumns = [
            {
                title: '#',
                dataIndex: 'key',
                key: 'key',
            },
            {
                title: 'Class ID',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Class ',
                dataIndex: 'papers',
                key: 'papers',
            },
            {
                title: 'Subject',
                dataIndex: 'subject',
                key: 'subject',
                sorter: {
                    compare: (a, b) => a.subject.localeCompare(b.subject),
                    multiple: 3,
                },
                filters: [
                    { text: 'CHEMISTRY', value: 'CHEMISTRY' },
                    { text: 'PHYSICS', value: 'PHYSICS' },
                    { text: 'BIOLOGY', value: 'BIOLOGY' },
                    { text: 'SHONA', value: 'SHONA' },
                    { text: 'ENGLISH', value: 'SHONA' },
                    { text: 'GEOGRAPHY', value: 'GEOGRAPHY' },
                    { text: 'HISTORY', value: 'HISTORY' },
                    { text: 'INTEGRATED SCIENCE', value: 'INTEGRATED SCIENCE' },
                    { text: 'MATHEMATICS', value: 'MATHEMATICS' },
                    { text: 'COMMERCE', value: 'COMMERCE' },
                    { text: 'AGRICULTURE', value: 'AGRICULTURE' },

                ],
                onFilter: (value, record) => record.subject === value,
            },
            {
                title: 'Level',
                dataIndex: 'level',
                key: 'level',
                sorter: {
                    compare: (a, b) => a.level.localeCompare(b.level),
                    multiple: 1,
                },
                filters: [
                    { text: 'FORM 1', value: 'FORM 1' },
                    { text: 'FORM 2', value: 'FORM 2' },
                    { text: 'FORM 3', value: 'FORM 3' },
                    { text: 'FORM 4', value: 'FORM 4' },
                    { text: 'FORM 5', value: 'FORM 5' },
                ],
                onFilter: (value, record) => record.level === value,
            },
            {
                title: 'Term',
                dataIndex: 'term',
                key: 'term',
                sorter: {
                    compare: (a, b) => a.term.localeCompare(b.term),
                    multiple: 1,
                },
                filters: [
                    { text: 'TERM 1', value: 'TERM 1' },
                    { text: 'TERM 2', value: 'TERM 2' },
                    { text: 'TERM 3', value: 'TERM 3' },
                ],
                onFilter: (value, record) => record.term === value,
            },
            {
                title: 'Action',
                dataIndex: '',
                key: '',
                render: (record) => (
                    <Space size="middle">
                        <Tooltip title="View exam info">
                            <Button
                                type="primary"
                                icon={<EyeOutlined />}
                                onClick={() => navigate(`/admin/examinations/${record?.name}/${record?.id}`)}
                            />
                        </Tooltip>
                    </Space>
                )
            }
        ]



        const handleTeacherSelect = async (value) => {
            try {
                setIsFetchingExaminations(true);
                const examsResponse = await EndTermExamService.getAllByInstitutionIdAndTermId(
                    AuthenticationService.getUserTenantId(),
                    value
                )
                setExaminations(examsResponse.data);
                setIsFetchingExaminations(false);
            } catch (e) {
                setIsFetchingExaminations(false);
                setExaminations([]);
            }
        }

        const tabs = [
            {
                label: 'Mark Exam',
                key: 1,
                children: (
                    <>
                        <div className='d-flex justify-content-between mb-3'>
                            <div>
                                <Select
                                    style={{ width: '400px' }}
                                    placeholder='Select teacher to mark'
                                    options={teachers}
                                    onChange={handleTeacherSelect}
                                />
                            </div>
                            <div>

                            </div>
                        </div>
                        
                    </>
                )
            },
        ]

        useEffect(() => {
            async function fetchAllTerms() {
                try {
                    const examsResponse = await EndTermExamService.getAllByInstitutionId(AuthenticationService.getUserTenantId());
                    const termsResponse = await SchoolTermServices.getAllTermsByInstitution(AuthenticationService.getUserTenantId());
                    const examsResponseData = examsResponse?.data;
                    const termsResponseData = termsResponse?.data;
                    setIsFetchingExaminations(false);
                    return { termsResponseData, examsResponseData }
                } catch (e) {
                    setIsFetchingExaminations(false);
                    return {};
                }
            }

            fetchAllTerms()
                .then(data => {
                    setExaminations(data?.examsResponseData);
                    setTerms(data?.termsResponseData);
                })
        }, [])

        return (
            <div className={"overflow-x-hidden"}>
                <div>
                    <h3>Set Exam Marks</h3>
                </div>

            

                <Tabs
                    defaultActiveKey={1}
                    className='mt-2'
                    items={tabs}
                />

            </div>
        )
    
}

export default AdminMarkExam;