import {Button, Result} from "antd";
import {useNavigate} from "react-router-dom";

const ComingSoon = () => {
    const navigate = useNavigate();
    return (
        <>
            <Result
                status="404"
                title="This feature is under development"
                subTitle="Stay tuned for updates!"
                extra={<Button onClick={() => navigate(-1)} type="primary">Go back</Button>}
            />
        </>
    )
}

export default ComingSoon;